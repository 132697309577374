<template>
  <div :class="classObj" class="app-wrapper">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <side-bar class="sidebar-container" />
    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
        <!--            <tags-view v-if="needTagsView" />-->
      </div>
      <section class="app-main">
        <router-view />
      </section>
      <!--          <right-panel v-if="showSettings">-->
      <!--            <settings />-->
      <!--          </right-panel>-->
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useState } from '../hooks/useState'
import { useStore } from 'vuex'

import { SideBar, Navbar } from './components'

const store = useStore()

const { sidebar, device } = useState('app', ['sidebar', 'device'])

const { showSettings, needTagsView, fixedHeader } = useState('settings', [
  'showSettings',
  'tagsView',
  'fixedHeader'
])

const classObj = computed(() => {
  return {
    hideSidebar: !sidebar.value.opened,
    withoutAnimation: sidebar.value.withoutAnimation,
    mobile: device.value === 'mobile',
    openSidebar: sidebar.value.opened
  }
})

store.dispatch('permission/generateRoutes')

const handleClickOutside = () => {
  store.dispatch('app/closeSideBar', { withoutAnimation: false })
}
</script>

<style scoped lang="scss">
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.app-main {
  padding: 20px;
}
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
