<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(context) {
    const { icon, title } = context
    const vnodes = []

    if (icon) {
      vnodes.push(<i class={[icon, 'sub-el-icon', 'iconfont']} />)
      // else {
      //   vnodes.push(<svg-icon icon-class={icon}/>)
      // }
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>)
    }
    return vnodes
  }
}
</script>

<style scoped>
.sub-el-icon {
  color: #ffffff;
  width: 16px;
  height: 16px;
  font-size: 16px;
}
</style>
