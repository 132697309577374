class LocalCache {
  setCache(key, value) {
    window.localStorage.setItem(key, value)
  }

  getCache(key) {
    // obj => string => obj
    return window.localStorage.getItem(key)
  }

  deleteCache(key) {
    window.localStorage.removeItem(key)
  }

  clearCache() {
    window.localStorage.clear()
  }
}

export default new LocalCache()
