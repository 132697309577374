import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout/Layout'
import { getToken } from '../utils/auth'

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/works'
    // children: [
    //   {
    //     path: 'dashboard',
    //     component: () => import('@/views/dashboard/index'),
    //     name: 'Dashboard',
    //     meta: { title: '首页', icon: 'icon-dashboard', affix: true }
    //   }
    // ]
  },
  {
    path: '/works',
    component: Layout,
    name: 'works',
    redirect: 'noRedirect',
    children: [
      {
        path: '',
        component: () => import('@/views/Works/index'),
        name: 'work',
        meta: { title: '作品列表', icon: 'icon-zuopin', noCache: true }
      }
    ]
  },
  {
    path: '/result',
    component: Layout,
    name: 'result',
    redirect: 'noRedirect',
    children: [
      {
        path: '',
        component: () => import('@/views/Result/index'),
        name: 'result',
        meta: {
          title: '获奖作品',
          icon: 'icon-keyanchengguohuojiang',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/period',
    component: Layout,
    name: 'period',
    redirect: 'noRedirect',
    children: [
      {
        path: '',
        component: () => import('@/views/Period/index'),
        name: 'period',
        meta: {
          title: '周期设置',
          icon: 'icon-zhouqiguanli',
          noCache: true
        }
      }
    ]
  },
  // {
  //   path: '/setting',
  //   component: Layout,
  //   name: 'setting',
  //   redirect: 'noRedirect',
  //   meta: { title: '设置', icon: 'icon-setting', noCache: true },
  //   children: [
  //     {
  //       path: 'bconfig',
  //       component: () => import('@/views/BConfig/index'),
  //       name: 'BConfig',
  //       meta: { title: 'b端设置', icon: 'icon-shanghuguanli', noCache: true }
  //     },
  //     {
  //       path: 'cconfig',
  //       component: () => import('@/views/CConfig/index'),
  //       name: 'CConfig',
  //       meta: { title: 'c端设置', icon: 'icon-kehu', noCache: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/users',
  //   component: Layout,
  //   name: 'users',
  //   redirect: 'noRedirect',
  //   // meta: { title: '用户', icon: 'icon-user', noCache: true },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/User/index'),
  //       name: 'user',
  //       meta: { title: '用户', icon: 'icon-user', noCache: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/cabinets',
  //   component: Layout,
  //   name: 'cabinets',
  //   redirect: 'noRedirect',
  //   // meta: { title: '用户', icon: 'icon-user', noCache: true },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/Cabinet/index'),
  //       name: 'cabinet',
  //       meta: { title: '箱柜', icon: 'icon-box', noCache: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/reserve',
  //   component: Layout,
  //   // name: 'cabinets',
  //   redirect: 'noRedirect',
  //   // meta: { title: '用户', icon: 'icon-user', noCache: true },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/Reserve/index'),
  //       name: 'reserve',
  //       meta: { title: '预约', icon: 'icon-yuyuedingdan', noCache: true }
  //     }
  //   ]
  // },
  {
    path: '/login',
    component: () => import('@/views/Login/login'),
    name: 'Login'
    // meta: { title: '登录' }
  }
]

export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title || '登录'} | 后台管理`
  const hasToken = getToken()
  if (!hasToken && to.path !== '/login') {
    next('/login')
  } else {
    next()
  }
})

export default router
