import Cache from './cache'

const TokenKey = 'token'

export function getToken() {
  return Cache.getCache(TokenKey)
}

export function setToken(token) {
  return Cache.setCache(TokenKey, token)
}

export function removeToken() {
  return Cache.deleteCache(TokenKey)
}
