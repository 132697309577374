<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <!--      <template v-if="device !== 'mobile'">-->
      <!--        <search id="header-search" class="right-menu-item" />-->

      <!--        <error-log class="errLog-container right-menu-item hover-effect" />-->

      <!--        <screenfull id="screenfull" class="right-menu-item hover-effect" />-->

      <!--        <el-tooltip-->
      <!--          :content="$t('navbar.size')"-->
      <!--          effect="dark"-->
      <!--          placement="bottom"-->
      <!--        >-->
      <!--          <size-select id="size-select" class="right-menu-item hover-effect" />-->
      <!--        </el-tooltip>-->

      <!--        <lang-select class="right-menu-item hover-effect" />-->
      <!--      </template>-->

      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <img :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar" />
          <!--          <i class="icon-xiala iconfont" />-->
        </div>
        <template #dropdown>
          <el-dropdown-item slot="dropdown">
            <span @click="logout">退出登录</span>
          </el-dropdown-item>
        </template>
        <!--        <template #dropdown>-->
        <!--          <el-dropdown-menu slot="dropdown">-->
        <!--            &lt;!&ndash;          <router-link to="/profile/index">&ndash;&gt;-->
        <!--            <el-dropdown-item> navbar.profile </el-dropdown-item>-->
        <!--            &lt;!&ndash;          </router-link>&ndash;&gt;-->
        <!--            <router-link to="/">-->
        <!--              <el-dropdown-item> navbar.dashboard </el-dropdown-item>-->
        <!--            </router-link>-->
        <!--            <a-->
        <!--              target="_blank"-->
        <!--              href="https://github.com/PanJiaChen/vue-element-admin/"-->
        <!--            >-->
        <!--              <el-dropdown-item> navbar.github </el-dropdown-item>-->
        <!--            </a>-->
        <!--            <a-->
        <!--              target="_blank"-->
        <!--              href="https://panjiachen.github.io/vue-element-admin-site/#/"-->
        <!--            >-->
        <!--              <el-dropdown-item>Docs</el-dropdown-item>-->
        <!--            </a>-->
        <!--            <el-dropdown-item divided @click.native="logout">-->
        <!--              <span style="display: block">navbar.logOut</span>-->
        <!--            </el-dropdown-item>-->
        <!--          </el-dropdown-menu>-->
        <!--        </template>-->
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device'])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    logout() {
      window.localStorage.removeItem('token')
      this.$router.push('/login')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
