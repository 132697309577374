import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/element-variables.scss'
import 'normalize.css/normalize.css'

import './assets/icons/iconfont.css'
import '@/styles/index.scss' // global css
import zhCn from 'element-plus/es/locale/lang/zh-cn.js'

const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn
  })
  .mount('#app')
